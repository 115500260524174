import LanguagePicker from 'components/LanguagePicker'
import React from 'react'

const NoAuthLayout = ({ children }) => {
    return (
        <div className="m-noAuth">
            <div className="m-noAuth__language">
                <LanguagePicker />
            </div>
            <div className="_wr">
                <div className="_w">
                    <div className="m-noAuth__content _s8 _m7 _l6 _xl5">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoAuthLayout
