import React, { useState, useEffect, useContext } from 'react'

import {
    editEntityService,
    getEntityByIdService,
} from 'services/entity.service'
import { getUserLanguage, setUserLanguage } from 'services/localStorage.service'

import { CurrentUserContext } from './CurrentUserContext'

import ENTITY from 'constants/entities'
import { DEFAULT_LANGUAGE } from 'constants/constants'

export const TRANSLATIONS_URL = process.env.REACT_APP_TRANSLATIONS

export const TranslationContext = React.createContext()

const TranslationContextProvider = (props) => {
    const { currentUser, setCurrentUser } = useContext(CurrentUserContext)

    const defaultLanguage = getUserLanguage() || DEFAULT_LANGUAGE
    const version = Date.now();
    const [translations, setTranslations] = useState({
        locale: defaultLanguage,
        messages: require(`../translations/${defaultLanguage}.json`),
    })
    const [isLoading, setIsLoading] = useState(false)

    const fetchTranslations = (language) => {
        fetch(`${TRANSLATIONS_URL}/${language}.json?v=${version}`)
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                setTranslations({
                    locale: language,
                    messages: data,
                })
            })
            .catch((error) => {
                console.log(error)
                setTranslations({
                    locale: language,
                    messages: require(`../translations/${language}.json`),
                })
            })
    }

    useEffect(() => {
        fetchTranslations(defaultLanguage)
    }, [])

    useEffect(() => {
        if (currentUser) {
            const currentLanguage = currentUser?.locale || defaultLanguage
            fetchTranslations(currentLanguage)
        }
    }, [currentUser])

    const handleSetTranslations = async (language) => {
        try {
            setIsLoading(true)
            if (currentUser) {
                await editEntityService(ENTITY.USER, currentUser.id, {
                    locale: language.id,
                })
                const data = await getEntityByIdService(
                    ENTITY.USER,
                    currentUser.id,
                    { include: 'role,setting,group,superVisorGroup' }
                )
                setCurrentUser(data)
            } else {
                fetchTranslations(language.id)
            }
            setUserLanguage(language.id)
        } catch (error) {
            throw error
        } finally {
            setIsLoading(false)
        }
    }

    const { locale, messages } = translations
    if (!locale || !messages) return null

    return (
        <TranslationContext.Provider
            value={{
                translations,
                setTranslations: handleSetTranslations,
                fetchTranslations: fetchTranslations,
                isLoading,
            }}
        >
            {props.children}
        </TranslationContext.Provider>
    )
}

export default TranslationContextProvider
