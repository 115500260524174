import { createEntityService } from 'services/entity.service'

import {
    calculateDefaultPibValidFrom,
    calculateDefaultPibValidTo,
    formatAerodromePIBRequest,
    formatAreaPIBRequest,
    formatNarrowRoutePIBRequest,
    formatPointPIBRequest,
    formatPolygonPIBRequest,
    formatRoutePIBRequest,
} from 'utils/pibDataFormatters'

import ENTITY from 'constants/entities'
import { PIB_TYPE } from 'constants/pib'
import ROUTES from 'constants/routes'
import { ALERT_TYPES } from 'constants/enums'

export const createPreconfiguredPibService = async (
    { type, data, pibValidTo },
    navigate,
    setIsLoading,
    setAlert,
    t
) => {
    try {
        setIsLoading(true)

        const preconfiguredPibData = {
            ...data,
            ValidFrom: calculateDefaultPibValidFrom(),
            ValidTo: calculateDefaultPibValidTo(pibValidTo),
        }

        switch (type) {
            case PIB_TYPE.AERODROME:
                const aerodromeData =
                    formatAerodromePIBRequest(preconfiguredPibData)
                const aerodromeResponse = await createEntityService(
                    ENTITY.CREATE_AERODROME_PIB,
                    aerodromeData,
                    false
                )
                navigate(`${ROUTES.PIB}${ROUTES.AIRPORT}${ROUTES.PREVIEW}`, {
                    state: {
                        previewData: aerodromeResponse,
                    },
                })
                break
            case PIB_TYPE.AREA:
                const areaData = formatAreaPIBRequest(preconfiguredPibData)
                const areaResponse = await createEntityService(
                    ENTITY.CREATE_AREA_PIB,
                    areaData,
                    false
                )
                navigate(`${ROUTES.PIB}${ROUTES.AREA}${ROUTES.PREVIEW}`, {
                    state: {
                        previewData: areaResponse,
                    },
                })
                break
            case PIB_TYPE.ROUTE:
                const {
                    alternateFIRMap,
                    FirDepAerodrome,
                    FirDestAerodrome,
                    ...restData
                } = preconfiguredPibData
                const routeData = formatRoutePIBRequest(
                    restData,
                    alternateFIRMap,
                    FirDepAerodrome,
                    FirDestAerodrome
                )

                const routeResponse = await createEntityService(
                    ENTITY.CREATE_ROUTE_PIB,
                    routeData,
                    false,
                    null,
                    false
                )
                navigate(`${ROUTES.PIB}${ROUTES.ROUTE}${ROUTES.PREVIEW}`, {
                    state: {
                        previewData: routeResponse,
                    },
                })
                break
            case PIB_TYPE.NARROW_ROUTE:
                const narrowRouteData =
                    formatNarrowRoutePIBRequest(preconfiguredPibData)
                const narrowRouteResponse = await createEntityService(
                    ENTITY.CREATE_NARROW_ROUTE_PIB,
                    narrowRouteData,
                    false,
                    null,
                    false
                )
                navigate(`${ROUTES.PIB}${ROUTES.NARROW}${ROUTES.PREVIEW}`, {
                    state: {
                        previewData: narrowRouteResponse,
                    },
                })
                break
            case PIB_TYPE.POINT:
                const pointData = formatPointPIBRequest(preconfiguredPibData)
                const pointResponse = await createEntityService(
                    ENTITY.CREATE_POINT_PIB,
                    pointData,
                    false
                )
                navigate(`${ROUTES.PIB}${ROUTES.POINT}${ROUTES.PREVIEW}`, {
                    state: {
                        previewData: pointResponse,
                    },
                })
                break
            case PIB_TYPE.POLYGON:
                const polygonData =
                    formatPolygonPIBRequest(preconfiguredPibData)
                const polygonResponse = await createEntityService(
                    ENTITY.CREATE_POLYGON_PIB,
                    polygonData,
                    false
                )
                navigate(`${ROUTES.PIB}${ROUTES.POLYGON}${ROUTES.PREVIEW}`, {
                    state: {
                        previewData: polygonResponse,
                    },
                })
                break
            default:
                break
        }
        setAlert(t('message.pibCreatedSuccessfully'), ALERT_TYPES.SUCCESS)
    } catch (error) {
        setAlert(error, ALERT_TYPES.ERROR)
    } finally {
        setIsLoading(false)
    }
}
