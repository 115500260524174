import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import ROUTES from 'constants/routes'

import LanguagePicker from 'components/LanguagePicker'
import AvatarDropdown from './AvatarDropdown'

import logo from 'assets/images/logo.png'

const Header = ({ openSidebar, setOpenSidebar, hamBtnRef }) => {
    const t = useTranslate()

    return (
        <header className="m-header -topLevel">
            <div className="m-header__nav">
                <div
                    className="m-toggler"
                    ref={hamBtnRef}
                    onClick={() => setOpenSidebar(!openSidebar)}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="m-header__logo">
                    <Link to={ROUTES.HOME}>
                        <img src={logo} alt="Logo" />
                    </Link>

                    <span>{t('general.logoTitle')}</span>
                </div>
            </div>
            <div className="m-header__main">
                <LanguagePicker />
                <AvatarDropdown />
            </div>
        </header>
    )
}

Header.propTypes = {
    openSidebar: PropTypes.bool.isRequired,
    setOpenSidebar: PropTypes.func.isRequired,
}

export default Header
