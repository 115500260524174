import moment from 'moment'

export const LARGE_SCREEN_WIDTH = 991
export const SMALL_SCREEN_WIDTH = 768

export const DEFAULT_LANGUAGE = 'pt'
export const ALERT_DURATION = 5000
export const REFRESH_FPL_OVERVIEW = 60000
export const REFRESH_FPL_OVERVIEW_MANAGER = 300000

export const DEFAULT_NOTICE_VALID_FROM =
    moment().utc().format('YYYY-MM-DD').toString() + 'T00:00:00'
export const DEFAULT_NOTICE_VALID_TO =
    moment().utc().format('YYYY-MM-DD').toString() + 'T23:59:59'

export const FPL_OVERVIEW_FROM = 15
export const FPL_OVERVIEW_TO = 15
export const PIB_VALID_TO = 1

export const FPL_OVERVIEW_FROM_MANAGER = 0

