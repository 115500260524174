import React, { useEffect, useState } from 'react'

import { getUserId } from 'services/localStorage.service'

import useFetchDataById from 'hooks/useFetchDataById'

import ENTITY from 'constants/entities'
import { ROLES } from 'constants/enums'

export const CurrentUserContext = React.createContext()

const CurrentUserContextProvider = (props) => {
    const [currentUser, setCurrentUser] = useState(null)
    const [isAdmin, setIsAdmin] = useState(false)
    const [isManager, setIsManager] = useState(false)

    const userId = getUserId()
    const { data, isLoading: isLoadingCurrentUser } = useFetchDataById(
        ENTITY.USER,
        userId,
        {
            include: 'role,setting,group,superVisorGroup',
        },
        false,
        currentUser === null
    )

    useEffect(() => {
        setCurrentUser(data)
    }, [data])

    useEffect(() => {
        if (currentUser?.role?.name === ROLES.ROLE_MANAGER.name) {
            setIsManager(true)
        } else {
            setIsManager(false)
        }
        if (currentUser?.role?.name === ROLES.ROLE_ADMIN.name) {
            setIsAdmin(true)
        } else {
            setIsAdmin(false)
        }
    }, [currentUser])

    return (
        <CurrentUserContext.Provider
            value={{
                currentUser,
                setCurrentUser,
                isLoadingCurrentUser,
                userId,
                isAdmin,
                isManager,
            }}
        >
            {props.children}
        </CurrentUserContext.Provider>
    )
}

export default CurrentUserContextProvider
