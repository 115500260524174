import { getTableStorageId } from "utils/tableHelper";
import { getTableQueryParams } from "services/localStorage.service";

export const fetchURL = (key, to, currentUser) => {
    let pathname = to

    const tableStorageKey = getTableStorageId(key, currentUser)
    const tableQueryParams = getTableQueryParams(tableStorageKey)

    if (tableQueryParams && tableQueryParams.substring(0, 1) === '?') {
        pathname += tableQueryParams
    }

    return pathname
}
