/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'

import ROUTES from 'constants/routes'
import { ALL_ROLES, ROLES } from 'constants/enums'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { DefaultSettingsContext } from 'contexts/DefaultSettingsContext'

import { createPreconfiguredPibService } from 'services/pib.service'
import { SCREEN_KEY } from "../../constants/screenKey";

const { ROLE_ADMIN, ROLE_MANAGER, ROLE_USER } = ROLES

const MENU_ITEMS = [
    {
        key: SCREEN_KEY.HOME,
        label: 'menu.home',
        icon: 'home',
        to: ROUTES.HOME,
        roles: ALL_ROLES,
    },
    {
        key: SCREEN_KEY.PIB,
        label: 'menu.pib',
        icon: 'pib',
        to: ROUTES.PIB,
        roles: ALL_ROLES,
        subitems: [
            {
                key: SCREEN_KEY.AIRPORT_PIB,
                label: 'menu.airport',
                to: `${ROUTES.PIB}${ROUTES.AIRPORT}`,
                icon: 'plane-arrival',
            },
            {
                key: SCREEN_KEY.AREA_PIB,
                label: 'menu.area',
                to: `${ROUTES.PIB}${ROUTES.AREA}`,
                icon: 'area',
            },
            {
                key: SCREEN_KEY.ROUTE_PIB,
                label: 'menu.route',
                to: `${ROUTES.PIB}${ROUTES.ROUTE}`,
                icon: 'route',
            },
            {
                key: SCREEN_KEY.NARROW_ROUTE_PIB,
                label: 'menu.narrow',
                to: `${ROUTES.PIB}${ROUTES.NARROW}`,
                icon: 'narrow',
            },
            {
                key: SCREEN_KEY.POINT_PIB,
                label: 'menu.point',
                to: `${ROUTES.PIB}${ROUTES.POINT}`,
                icon: 'point',
            },
            {
                key: SCREEN_KEY.POLYGON_PIB,
                label: 'menu.polygon',
                to: `${ROUTES.PIB}${ROUTES.POLYGON}`,
                icon: 'polygon',
            },
            {
                key: SCREEN_KEY.HANDBOOK,
                label: 'menu.handbook',
                to: `${ROUTES.PIB}${ROUTES.HANDBOOK}`,
                icon: 'handbook',
            },
        ],
    },
    {
        key: SCREEN_KEY.FPL,
        label: 'menu.fpl',
        icon: 'fpl',
        to: ROUTES.FPL,
        roles: ALL_ROLES,
        subitems: [
            {
                key: SCREEN_KEY.FPL_CREATE,
                label: 'menu.fplCreate',
                to: `${ROUTES.FPL}${ROUTES.CREATE}`,
                icon: 'square-plus',
            },
            {
                key: SCREEN_KEY.OVERVIEW,
                label: 'menu.fplOverview',
                to: `${ROUTES.FPL}${ROUTES.OVERVIEW}`,
                icon: 'overview',
            },
            {
                key: SCREEN_KEY.TEMPLATE,
                label: 'menu.fplTemplate',
                to: `${ROUTES.FPL}${ROUTES.TEMPLATE}`,
                icon: 'template',
            },
        ],
    },
    {
        key: SCREEN_KEY.AIP_LIBRARY,
        label: 'menu.aip',
        icon: 'library',
        to: ROUTES.AIP,
        roles: ALL_ROLES,
    },
    {
        key: SCREEN_KEY.ADMIN,
        label: 'menu.admin',
        icon: 'administration',
        to: ROUTES.ADMIN,
        checkSubitems: true,
        roles: [ROLE_ADMIN, ROLE_MANAGER],
        subitems: [
            {
                key: SCREEN_KEY.USERS,
                label: 'menu.userManagement',
                to: `${ROUTES.ADMIN}${ROUTES.USERS}`,
                roles: [ROLE_ADMIN],
                icon: 'users',
            },
            {
                key: SCREEN_KEY.TRANSLATION,
                label: 'menu.translations',
                to: `${ROUTES.ADMIN}${ROUTES.TRANSLATIONS}`,
                roles: [ROLE_ADMIN],
                icon: 'translation',
            },
            {
                key: SCREEN_KEY.FEEDBACK,
                label: 'menu.feedback',
                to: `${ROUTES.ADMIN}${ROUTES.FEEDBACK}`,
                roles: [ROLE_ADMIN],
                icon: 'feedback',
            },
            {
                key: SCREEN_KEY.CMS,
                label: 'menu.cms',
                to: `${ROUTES.ADMIN}${ROUTES.PAGES}`,
                roles: [ROLE_ADMIN],
                icon: 'cms',
            },
            {
                key: SCREEN_KEY.NOTICES,
                label: 'menu.notices',
                to: `${ROUTES.ADMIN}${ROUTES.NOTICES}`,
                roles: [ROLE_ADMIN],
                icon: 'bell',
            },
            {
                key: SCREEN_KEY.BROADCAST_MESSAGE,
                label: 'menu.broadcastMessages',
                to: `${ROUTES.ADMIN}${ROUTES.MESSAGES}`,
                roles: [ROLE_ADMIN],
                icon: 'messages-open',
            },
            {
                key: SCREEN_KEY.GROUP,
                label: 'menu.group',
                to: `${ROUTES.ADMIN}${ROUTES.GROUPS}`,
                roles: [ROLE_ADMIN, ROLE_MANAGER],
                icon: 'group',
            },
            {
                key: SCREEN_KEY.PRECONFIGURED_PIB,
                label: 'menu.preconfiguredPib',
                to: `${ROUTES.ADMIN}${ROUTES.PRECONFIGURED_PIB}`,
                roles: [ROLE_ADMIN],
                icon: 'group',
            },
            {
                key: SCREEN_KEY.SETTINGS,
                label: 'menu.settings',
                to: `${ROUTES.ADMIN}${ROUTES.SETTINGS}`,
                roles: [ROLE_ADMIN],
                icon: 'settings',
            },
            {
                key: SCREEN_KEY.MENU_DISPLAY_SETTINGS,
                label: 'menu.menuDisplaySettings',
                to: `${ROUTES.ADMIN}${ROUTES.MENU_DISPLAY_SETTINGS}`,
                roles: [ROLE_ADMIN],
                icon: 'site-services',
            },
            {
                key: SCREEN_KEY.LOGS,
                label: 'menu.logs',
                to: `${ROUTES.ADMIN}${ROUTES.LOGS}`,
                roles: [ROLE_ADMIN],
                icon: 'logs',
            },
        ],
    },
    {
        key: SCREEN_KEY.STATISTICS,
        label: 'menu.statistics',
        icon: 'statistics',
        to: ROUTES.STATISTICS,
        roles: [ROLE_ADMIN],
        subitems: [
            {
                key: SCREEN_KEY.STATISTICS,
                label: 'menu.statisticsUsers',
                to: `${ROUTES.STATISTICS}${ROUTES.USERS}`,
                icon: 'statistics-user',
            },
            {
                key: SCREEN_KEY.STATISTIC_PERFLIGHT,
                label: 'menu.statisticsPreFlight',
                to: `${ROUTES.STATISTICS}${ROUTES.PREFLIGHTS}`,
                icon: 'statistics-preflight',
            },
            {
                key: SCREEN_KEY.STATISTIC_FLIGHT,
                label: 'menu.statisticsFlight',
                to: `${ROUTES.STATISTICS}${ROUTES.FLIGHTS}`,
                icon: 'statistics-flight',
            },
        ],
    },
    {
        key: SCREEN_KEY.REQUESTS,
        label: 'menu.requests',
        icon: 'requests',
        to: ROUTES.GROUP_REQUESTS,
        roles: [ROLE_MANAGER, ROLE_USER],
    },
    {
        key: SCREEN_KEY.PERSONAL,
        label: 'menu.personal',
        icon: 'user',
        to: ROUTES.PERSONAL,
        roles: ALL_ROLES,
        subitems: [
            {
                key: SCREEN_KEY.PERSONAL_AERODROMES,
                label: 'menu.personalAerodromes',
                to: `${ROUTES.PERSONAL}${ROUTES.AERODROMES}`,
                icon: 'plane-departure',
            },
            {
                key: SCREEN_KEY.PERSONAL_FIRS,
                label: 'menu.personalFIRS',
                to: `${ROUTES.PERSONAL}${ROUTES.FIRS}`,
                icon: 'personal-firs',
            },
            {
                key: SCREEN_KEY.PERSONAL_ROUTES,
                label: 'menu.personalRoutes',
                to: `${ROUTES.PERSONAL}${ROUTES.ROUTES}`,
                icon: 'personal-routes',
            },
            {
                key: SCREEN_KEY.PERSONAL_SETTINGS,
                label: 'menu.personalSettings',
                to: `${ROUTES.PERSONAL}${ROUTES.SETTINGS}`,
                icon: 'settings',
            },
            {
                key: SCREEN_KEY.PERSONAL_FEEDBACK,
                label: 'menu.personalFeedback',
                to: `${ROUTES.PERSONAL}${ROUTES.FEEDBACK}`,
                icon: 'feedback',
            },
        ],
    },
]

const useMenuItems = () => {
    const { currentUser } = useContext(CurrentUserContext)
    const {
        menuDisplaySettings,
        cmsPages,
        preconfiguredPibs,
        defaultSettings,
    } = useContext(DefaultSettingsContext)

    const [menuItems, setMenuItems] = useState([])

    useEffect(() => {
        if (currentUser && menuDisplaySettings) {
            filterMenuItems()
        }
    }, [currentUser, menuDisplaySettings, cmsPages, preconfiguredPibs])

    const filterMenuItems = () => {
        const userSettings = currentUser.setting
        const filteredMenuItems = MENU_ITEMS.reduce(
            (acc, { subitems, ...item }) => {
                const hasPermission = item.roles.some(
                    (role) => role.name === currentUser.role.name
                )
                if (
                    hasPermission &&
                    menuDisplaySettings[item.key] !== false &&
                    userSettings[item.key] !== false
                ) {
                    let menuItem = { ...item }
                    if (item.checkSubitems && subitems) {
                        menuItem.subitems = subitems.filter(
                            (subitem) =>
                                subitem.roles.some(
                                    (role) =>
                                        role.name === currentUser.role.name
                                ) &&
                                menuDisplaySettings[subitem.key] !== false &&
                                userSettings[subitem.key] !== false
                        )
                    } else if (subitems) {
                        menuItem.subitems = subitems.filter((subitem) => {
                            return (
                                menuDisplaySettings[subitem.key] !== false &&
                                userSettings[subitem.key] !== false
                            )
                        })
                    }
                    acc.push(menuItem)
                }
                return acc
            },
            []
        )

        const cmsMenuItems = cmsPages
            .filter((el) => el.id !== 'home')
            .map(({ id, title }) => ({
                key: id,
                label: title,
                icon: 'note',
                to: `${ROUTES.CMS}/${id}`,
                roles: ALL_ROLES,
                noTranslation: true,
            }))

        const preconfiguredPibMenuItems = preconfiguredPibs.map(
            ({ id, name, content, _type }) => ({
                key: `preconfiguredPib${id}`,
                label: name,
                icon: 'note',
                handleAction: createPreconfiguredPibService,
                actionParams: {
                    type: _type,
                    data: content,
                    pibValidTo: defaultSettings?.pibValidTo,
                },
                roles: ALL_ROLES,
                noTranslation: true,
            })
        )

        const pibIndex = filteredMenuItems.findIndex((el) => el.key === 'pib')
        if (pibIndex !== -1) {
            filteredMenuItems[pibIndex].subitems = [
                ...filteredMenuItems[pibIndex].subitems,
                ...preconfiguredPibMenuItems,
            ]
        }

        setMenuItems([...filteredMenuItems, ...cmsMenuItems])
    }

    return menuItems
}

export default useMenuItems
