export const SCREEN_KEY = {
    HOME: 'home',
    PIB: 'pib',
    AIRPORT_PIB: 'airportPib',
    AREA_PIB: 'areaPib',
    ROUTE_PIB: 'routePib',
    NARROW_ROUTE_PIB: 'narrowRoutePib',
    POINT_PIB: 'pointPib',
    POLYGON_PIB: 'polygonPib',
    HANDBOOK: 'handbook',
    FPL: 'fpl',
    FPL_CREATE: 'create',
    OVERVIEW: 'overview',
    TEMPLATE: 'template',
    AIP_LIBRARY: 'aipLibrary',
    ADMIN: 'admin',
    USERS: 'users',
    TRANSLATION: 'translations',
    FEEDBACK: 'feedback',
    CMS: 'cms',
    NOTICES: 'notices',
    BROADCAST_MESSAGE: 'broadcast_messages',
    GROUP: 'group',
    PRECONFIGURED_PIB: 'preconfigured_pib',
    SETTINGS: 'settings',
    MENU_DISPLAY_SETTINGS: 'menu_display_settings',
    LOGS: 'logs',
    STATISTICS: 'statistics',
    STATISTICS_USERS: 'statisticsUsers',
    STATISTIC_PERFLIGHT: 'statisticsPreFlight',
    STATISTIC_FLIGHT: 'statisticsFlight',
    REQUESTS: 'requests',
    PERSONAL: 'personal',
    PERSONAL_AERODROMES: 'personalAerodromes',
    PERSONAL_FIRS: 'personalFIRS',
    PERSONAL_ROUTES: 'personalRoutes',
    PERSONAL_SETTINGS: 'personalSettings',
    PERSONAL_FEEDBACK: 'personalFeedback',
}
