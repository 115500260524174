import React, { Fragment, useContext, useState } from 'react'
import PropTypes from 'prop-types'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { AlertContext } from 'contexts/AlertContext'

import useWindowDimensions from 'hooks/useWindowDimension'

import { SMALL_SCREEN_WIDTH } from 'constants/constants'

import Loader from 'components/Loader'
import useMenuItems from './useMenuItems'
import SidebarItem from './SidebarItem'

const Sidebar = ({ open, setOpen, hocRef }) => {
    const { currentUser } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const menuItems = useMenuItems(currentUser?.userRoles)

    const { width } = useWindowDimensions()

    const [active, setActive] = useState()
    const [isLoadingAction, setIsLoadingAction] = useState(false)

    return (
        <Fragment>
            <div
                ref={hocRef}
                className={`m-sidebar -lowerLevel ${open ? '' : '-closed'}`}
            >
                <div
                    className="m-sidebar__toggler"
                    onClick={() => setOpen(!open)}
                />
                <div className="m-sidebar__scroll">
                    {menuItems.map(
                        ({
                            key,
                            label,
                            to,
                            icon,
                            subitems,
                            noTranslation,
                        }) => (
                            <SidebarItem
                                key={key}
                                itemKey={key}
                                label={label}
                                to={to}
                                icon={icon}
                                subitems={subitems}
                                active={active}
                                setActive={setActive}
                                noTranslation={noTranslation}
                                setOpen={setOpen}
                                isMobileScreen={width < SMALL_SCREEN_WIDTH}
                                setIsLoadingAction={setIsLoadingAction}
                                setAlert={setAlert}
                            />
                        )
                    )}
                </div>
            </div>
            {isLoadingAction && <Loader />}
        </Fragment>
    )
}

Sidebar.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
}

export default Sidebar
