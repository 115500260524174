import React, { Fragment, useContext } from 'react'

import { TranslationContext } from 'contexts/TranslationContext'

import useFetchData from 'hooks/useFetchData'

import ENTITY from 'constants/entities'

import clickOutsideHOC from 'components/wrappers/clickOutsideHOC'
import Loader from 'components/Loader'

const LanguagePicker = ({ open }) => {
    const { translations, setTranslations, isLoading } =
        useContext(TranslationContext)

    const { data } = useFetchData(ENTITY.LANGUAGE, { pagination: false })

    const currentLanguage = data.find(
        (language) => language.id === translations.locale
    )

    if (!currentLanguage) return null

    return (
        <Fragment>
            <div className="m-languagePicker">
                <div>
                    <span className="m-languagePicker__container">
                        {currentLanguage.name}
                        <span
                            className={`a-chevron ${open ? '-up' : '-down'}`}
                        />
                    </span>
                </div>
                <ul
                    className={`m-languagePicker__popup ${open ? '-open' : ''}`}
                >
                    {data.map((language) => (
                        <li
                            key={language.id}
                            onClick={() => setTranslations(language)}
                        >
                            {language.name}
                        </li>
                    ))}
                </ul>
            </div>
            {isLoading && <Loader />}
        </Fragment>
    )
}

export default clickOutsideHOC(LanguagePicker)
