import moment from 'moment'

export const DEFAULT_PIB_ID = moment().format('YYMMDDHHmmss').toString()
export const DEFAULT_PIB_VALID_FROM = moment().utc().format('YYYY-MM-DDTHH:mm')
export const DEFAULT_PIB_VALID_TO = moment()
    .add(1, 'days')
    .utc()
    .format('YYYY-MM-DDTHH:mm')

export const PIB_MESSAGE_TYPES = [
    { id: 'NOTAM', name: 'NOTAM' },
    { id: 'SNOWTAM', name: 'SNOWTAM' },
    { id: 'ASHTAM', name: 'ASHTAM' },
    { id: 'AIM', name: 'AIM' },
    { id: 'ANM', name: 'ANM' },
    { id: 'METEO', name: 'METEO' },
    //BIRDTAM IS REMOVED FOR NOW
]

export const BRIEFING_TYPES = [
    { id: 'I', name: 'INT', order: 1 },
    { id: 'N', name: 'NAT', order: 2 },
    { id: 'M', name: 'MIL', order: 3 },
]

export const FLIGHT_RULES_PIB = [
    { id: 'I', name: 'IFR' },
    { id: 'V', name: 'VFR' },
    { id: 'IV', name: 'IFR / VFR' },
]

export const NOTAM_PURPOSES = [
    { id: 'B', name: 'Standard' },
    { id: 'M', name: 'All' },
]

export const PIB_MARKER_BUTTONS = [
    {
        label: 'fpl.form.adepShort',
        field: 'DepAerodrome',
    },
    {
        label: 'fpl.form.adesShort',
        field: 'DestAerodrome',
    },
]

export const COORDINATES_MARKER_BUTTONS = [
    {
        label: 'pib.form.geoCoordinates',
        field: 'GeoCoordinates',
    },
    {
        label: 'pib.form.radiusShort',
        field: 'Radius',
    },
]

export const POLYGON_COORDINATES_MARKER_BUTTONS = [
    {
        label: 'pib.form.coordinate',
        field: 'PolygonCoordinates',
    },
]

export const AERODROME_MARKER_BUTTONS = [
    {
        label: 'fpl.form.select',
        field: 'AlternateList',
    },
]

export const PIB_TYPE = {
    AERODROME: 'Aerodrome',
    AREA: 'Area',
    ROUTE: 'Route',
    NARROW_ROUTE: 'NarrowRoute',
    POINT: 'Point',
    POLYGON: 'Polygon',
}

export const PIB_TYPES = (t) => [
    { id: PIB_TYPE.AERODROME, name: t('pib.types.aerodrome') },
    { id: PIB_TYPE.AREA, name: t('pib.types.area') },
    { id: PIB_TYPE.ROUTE, name: t('pib.types.route') },
    { id: PIB_TYPE.NARROW_ROUTE, name: t('pib.types.narrowRoute') },
    { id: PIB_TYPE.POINT, name: t('pib.types.point') },
    { id: PIB_TYPE.POLYGON, name: t('pib.types.polygon') },
]

export const PIB_TERMS = (t) => [
    {
        id: 'short_term',
        name: t('pib.shortTerm'),
    },
    {
        id: 'long_term',
        name: t('pib.longTerm'),
    },
]
