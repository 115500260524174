import React, { useContext } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import ROUTES from 'constants/routes'
import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'

import { fetchURL } from 'utils/urlHelper';

const SideBarItem = ({
    label,
    to,
    icon,
    subitems,
    active,
    setActive,
    noTranslation,
    setOpen,
    isMobileScreen,
    setIsLoadingAction,
    setAlert,
    itemKey,
}) => {
    const { currentUser } = useContext(CurrentUserContext)

    const t = useTranslate()
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const handleClick = (
        e,
        { to, handleAction, actionParams, tableWithFilters, key }
    ) => {
        e.stopPropagation()
        e.preventDefault()
        if (handleAction) {
            handleAction(
                actionParams,
                navigate,
                setIsLoadingAction,
                setAlert,
                t
            )
        } else {
            navigate(fetchURL(key, to, currentUser))
        }
        isMobileScreen && setOpen(false)
    }

    const handleSetActive = () => {
        if (subitems) {
            setActive(active === to ? null : to)
        } else {
            setActive(to)
            isMobileScreen && setOpen(false)
        }
    }

    const getIsActive = (path) => {
        if (path !== ROUTES.HOME) return pathname.includes(path)
        return pathname === ROUTES.HOME
    }

    return (
        <Link
            className={`m-sidebar__itemContainer ${
                active === to ? ' -active' : ''
            }`}
            onClick={handleSetActive}
            to={subitems ? pathname : fetchURL(itemKey, to)}
        >
            <div
                className={`m-sidebar__item ${
                    getIsActive(to) ? ' -active' : ''
                }`}
                title={noTranslation ? label : t(label)}
            >
                <Icon name={icon} color="#929BB3" size={ICON_SIZE.SMALL} />
                <span className="m-sidebar__itemLabel">
                    {noTranslation ? label : t(label)}
                </span>
                {subitems && <span className="m-sidebar__subItemsIcon"></span>}
            </div>
            {subitems && (
                <div className="m-sidebar__subitems">
                    {subitems.map((item) => (
                        <div
                            className={`m-sidebar__subitem ${
                                getIsActive(item.to) ? ' -active' : ''
                            }`}
                            key={item.key}
                            onClick={(e) => handleClick(e, item)}
                            title={
                                item.noTranslation ? item.label : t(item.label)
                            }
                        >
                            <Icon
                                name={item.icon}
                                color="#B4B9C4"
                                size={ICON_SIZE.SMALL}
                            />
                            <span className="m-sidebar__subitemLabel">
                                {item.noTranslation
                                    ? item.label
                                    : t(item.label)}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </Link>
    )
}

SideBarItem.propTypes = {
    label: PropTypes.string.isRequired,
    to: PropTypes.string,
    icon: PropTypes.string,
    subitems: PropTypes.array,
    active: PropTypes.string,
    setActive: PropTypes.func,
    setOpen: PropTypes.func,
    isMobileScreen: PropTypes.bool,
    itemKey: PropTypes.string,
}

export default SideBarItem
