import React from 'react'
import { PropTypes } from 'prop-types'

export const ICON_SIZE = {
    LARGE: 24,
    SMALL: 18,
}

const getViewBoxSize = (size) => {
    switch (size) {
        case ICON_SIZE.LARGE:
        case ICON_SIZE.SMALL:
        default:
            return 24
    }
}

const renderIcon = (name, color, size, fill, title) => {
    const boxSize = getViewBoxSize(size)
    switch (name) {
        case 'home':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-home"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M5 12H3l9-9 9 9h-2M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-7" />
                </svg>
            )
        case 'chevron-left':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-chevron-left"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M15 6L9 12L15 18" stroke="currentColor" />
                </svg>
            )
        case 'chevron-right':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-chevron-right"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M9 6L15 12L9 18" stroke="currentColor" />
                </svg>
            )
        case 'sort':
            return (
                <svg
                    width={size}
                    height={size}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g fill="none" fillRule="evenodd">
                        <path fill="none" d="M-130-99h1440v700H-130z" />
                        <g
                            stroke="#9C9FA9"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M1 .857h6M1 6h6M1 11.143h7.714M10.429 3.429L13" />
                        </g>
                    </g>
                </svg>
            )
        case 'sort-asc':
            return (
                <svg
                    width={size}
                    height={12}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g fill="none" fillRule="evenodd">
                        <path fill="none" d="M-130-99h1440v700H-130z" />
                        <g
                            stroke="#9C9FA9"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M1 .857h6M1 6h6M1 11.143h7.714M10.429 3.429L13 .857l2.571 2.572M13 .857v10.286" />
                        </g>
                    </g>
                </svg>
            )
        case 'sort-desc':
            return (
                <svg
                    width={size}
                    height={12}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g fill="none" fillRule="evenodd">
                        <path fill="none" d="M-214-99h1440v700H-214z" />
                        <g
                            stroke="#9C9FA9"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M1 .857h7.714M1 6h6M1 11.143h6M10.429 8.571L13 11.143l2.571-2.572M13 .857v10.286" />
                            <g>
                                <path d="M1 .857h7.714M1 6h6M1 11.143h6M10.429 8.571L13 11.143l2.571-2.572M13 .857v10.286" />
                            </g>
                        </g>
                    </g>
                </svg>
            )
        case 'pencil':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-pencil"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
                    <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                </svg>
            )
        case 'trash':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-trash"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1="4" y1="7" x2="20" y2="7" />
                    <line x1="10" y1="11" x2="10" y2="17" />
                    <line x1="14" y1="11" x2="14" y2="17" />
                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                </svg>
            )
        case 'user':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-user"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="7" r="4" />
                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                </svg>
            )
        case 'lock':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-lock"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x="5" y="11" width="14" height="10" rx="2" />
                    <circle cx="12" cy="16" r="1" />
                    <path d="M8 11v-4a4 4 0 0 1 8 0v4" />
                </svg>
            )
        case 'email':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-at"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="12" r="4" />
                    <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28" />
                </svg>
            )
        case 'check':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-check"
                    width={size}
                    height={size}
                    viewBox={`0 0 ${boxSize} ${boxSize}`}
                    strokeWidth="3"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="m5 12 5 5L20 7" />
                </svg>
            )
        case 'pib':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-calendar-minus"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <rect x="4" y="5" width="16" height="16" rx="2" />
                    <path d="M16 3v4M8 3v4M4 11h16M10 16h4" />
                </svg>
            )
        case 'fpl':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-plane"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M16 10h4a2 2 0 0 1 0 4h-4l-4 7H9l2-7H7l-2 2H2l2-4-2-4h3l2 2h4L9 3h3z" />
                </svg>
            )
        case 'plane-arrival':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-plane-arrival"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="m15.157 11.81 4.83 1.295a2 2 0 0 1-1.036 3.863L4.462 13.086 3.117 6.514l2.898.776 1.414 2.45 2.898.776-.12-7.279 2.898.777zM3 21h18" />
                </svg>
            )
        case 'area':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-flag"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M5 5v16M19 5v9M5 5a5 5 0 0 1 7 0 5 5 0 0 0 7 0M5 14a5 5 0 0 1 7 0 5 5 0 0 0 7 0" />
                </svg>
            )
        case 'route':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-line"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <circle cx="6" cy="18" r="2" />
                    <circle cx="18" cy="6" r="2" />
                    <path d="m7.5 16.5 9-9" />
                </svg>
            )
        case 'narrow':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-route"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <circle cx="6" cy="19" r="2" />
                    <circle cx="18" cy="5" r="2" />
                    <path d="M12 19h4.5a3.5 3.5 0 0 0 0-7h-8a3.5 3.5 0 0 1 0-7H12" />
                </svg>
            )
        case 'point':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-focus-2"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <circle cx="12" cy="12" r=".5" fill="currentColor" />
                    <circle cx="12" cy="12" r="7" />
                    <path d="M12 3v2M3 12h2M12 19v2M19 12h2" />
                </svg>
            )
        case 'handbook':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-clipboard-list"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M9 5H7a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-2" />
                    <rect x="9" y="3" width="6" height="4" rx="2" />
                    <path d="M9 12h.01M13 12h2M9 16h.01M13 16h2" />
                </svg>
            )
        case 'square-plus':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-square-plus"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <rect x="4" y="4" width="16" height="16" rx="2" />
                    <path d="M9 12h6M12 9v6" />
                </svg>
            )
        case 'circle-plus':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-circle-plus"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="12" r="9" />
                    <line x1="9" y1="12" x2="15" y2="12" />
                    <line x1="12" y1="9" x2="12" y2="15" />
                </svg>
            )
        case 'overview':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-list-details"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M13 5h8M13 9h5M13 15h8M13 19h5" />
                    <rect x="3" y="4" width="6" height="6" rx="1" />
                    <rect x="3" y="14" width="6" height="6" rx="1" />
                </svg>
            )
        case 'template':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-template"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <rect x="4" y="4" width="16" height="4" rx="1" />
                    <rect x="4" y="12" width="6" height="8" rx="1" />
                    <path d="M14 12h6M14 16h6M14 20h6" />
                </svg>
            )
        case 'library':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-file-text"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                    <path d="M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2zM9 9h1M9 13h6M9 17h6" />
                </svg>
            )
        case 'administration':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-layout-2"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <rect x="4" y="4" width="6" height="5" rx="2" />
                    <rect x="4" y="13" width="6" height="7" rx="2" />
                    <rect x="14" y="4" width="6" height="7" rx="2" />
                    <rect x="14" y="15" width="6" height="5" rx="2" />
                </svg>
            )
        case 'users':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-users"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2M16 3.13a4 4 0 0 1 0 7.75M21 21v-2a4 4 0 0 0-3-3.85" />
                </svg>
            )
        case 'translation':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-language"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M4 5h7M9 3v2c0 4.418-2.239 8-5 8" />
                    <path d="M5 9c-.003 2.144 2.952 3.908 6.7 4M12 20l4-9 4 9M19.1 18h-6.2" />
                </svg>
            )
        case 'feedback':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-message-report"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M4 21V8a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H8l-4 4M12 8v3M12 14v.01" />
                </svg>
            )
        case 'cms':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-layout-board-split"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <rect x="4" y="4" width="16" height="16" rx="2" />
                    <path d="M4 12h8M12 15h8M12 9h8M12 4v16" />
                </svg>
            )
        case 'bell':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-bell"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M10 5a2 2 0 0 1 4 0 7 7 0 0 1 4 6v3a4 4 0 0 0 2 3H4a4 4 0 0 0 2-3v-3a7 7 0 0 1 4-6M9 17v1a3 3 0 0 0 6 0v-1" />
                </svg>
            )
        case 'group':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-layout-grid"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <rect x="4" y="4" width="6" height="6" rx="1" />
                    <rect x="14" y="4" width="6" height="6" rx="1" />
                    <rect x="4" y="14" width="6" height="6" rx="1" />
                    <rect x="14" y="14" width="6" height="6" rx="1" />
                </svg>
            )
        case 'settings':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-settings"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066-2.573c-.94-1.543.826-3.31 2.37-2.37 1 .608 2.296.07 2.572-1.065z" />
                    <circle cx="12" cy="12" r="3" />
                </svg>
            )
        case 'site-services':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-grid-dots"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <circle cx="5" cy="5" r="1" />
                    <circle cx="12" cy="5" r="1" />
                    <circle cx="19" cy="5" r="1" />
                    <circle cx="5" cy="12" r="1" />
                    <circle cx="12" cy="12" r="1" />
                    <circle cx="19" cy="12" r="1" />
                    <circle cx="5" cy="19" r="1" />
                    <circle cx="12" cy="19" r="1" />
                    <circle cx="19" cy="19" r="1" />
                </svg>
            )
        case 'logs':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-file-code-2"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M10 12H9v5h1M14 12h1v5h-1M14 3v4a1 1 0 0 0 1 1h4" />
                    <path d="M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2z" />
                </svg>
            )
        case 'statistics':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-chart-line"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M4 19h16M4 15l4-6 4 2 4-5 4 4" />
                </svg>
            )
        case 'statistics-user':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-chart-bar"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <rect x="3" y="12" width="6" height="8" rx="1" />
                    <rect x="9" y="8" width="6" height="12" rx="1" />
                    <rect x="15" y="4" width="6" height="16" rx="1" />
                    <path d="M4 20h14" />
                </svg>
            )
        case 'statistics-preflight':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-chart-area"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M4 19h16M4 15l4-6 4 2 4-5 4 4v5H4" />
                </svg>
            )
        case 'statistics-flight':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-chart-area-line"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="m4 19 4-6 4 2 4-5 4 4v5H4M4 12l3-4 4 2 5-6 4 4" />
                </svg>
            )
        case 'requests':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-access-point"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M12 12v.01M14.828 9.172a4 4 0 0 1 0 5.656M17.657 6.343a8 8 0 0 1 0 11.314M9.168 14.828a4 4 0 0 1 0-5.656M6.337 17.657a8 8 0 0 1 0-11.314" />
                </svg>
            )
        case 'plane-departure':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-plane-departure"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="m14.639 10.258 4.83-1.294a2 2 0 0 1 1.035 3.863L6.015 16.71l-4.45-5.02 2.897-.776 2.45 1.414 2.897-.776-3.743-6.244 2.898-.777zM3 21h18" />
                </svg>
            )
        case 'personal-firs':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-map-2"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M18 6v.01M18 13l-3.5-5a4 4 0 1 1 7 0L18 13" />
                    <path d="M10.5 4.75 9 4 3 7v13l6-3 6 3 6-3v-2M9 4v13M15 15v5" />
                </svg>
            )
        case 'personal-routes':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-map"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="m3 7 6-3 6 3 6-3v13l-6 3-6-3-6 3V7M9 4v13M15 7v13" />
                </svg>
            )
        case 'export':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-download"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2M7 11l5 5 5-5M12 4v12" />
                </svg>
            )
        case 'no':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-x"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
            )
        case 'yes':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-check"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="m5 12 5 5L20 7" />
                </svg>
            )
        case 'save':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-device-floppy"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M6 4h10l4 4v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2" />
                    <circle cx="12" cy="14" r="2" />
                    <path d="M14 4v4H8V4" />
                </svg>
            )
        case 'plus':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-plus"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M12 5v14M5 12h14" />
                </svg>
            )
        case 'exit':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-logout"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M14 8V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-2" />
                    <path d="M7 12h14l-3-3m0 6 3-3" />
                </svg>
            )
        case 'search':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-search"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <circle cx="10" cy="10" r="7" />
                    <path d="m21 21-6-6" />
                </svg>
            )
        case 'map':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-map"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="m3 7 6-3 6 3 6-3v13l-6 3-6-3-6 3V7M9 4v13M15 7v13" />
                </svg>
            )
        case 'circle-minus':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-circle-minus"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <circle cx="12" cy="12" r="9" />
                    <path d="M9 12h6" />
                </svg>
            )
        case 'heart':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-heart"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M19.5 13.572 12 21l-7.5-7.428m0 0A5 5 0 1 1 12 7.006a5 5 0 1 1 7.5 6.572" />
                </svg>
            )
        case 'done':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-circle-check"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <title id="done-icon">{title}</title>
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <circle cx="12" cy="12" r="9" />
                    <path d="m9 12 2 2 4-4" />
                </svg>
            )
        case 'cancel':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-circle-x"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <title id="cancel-icon">{title}</title>
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <circle cx="12" cy="12" r="9" />
                    <path d="m10 10 4 4m0-4-4 4" />
                </svg>
            )
        case 'warning':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-alert-triangle"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <title id="warning-icon">{title}</title>
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M12 9v2m0 4v.01M5 19h14a2 2 0 0 0 1.84-2.75L13.74 4a2 2 0 0 0-3.5 0l-7.1 12.25A2 2 0 0 0 4.89 19" />
                </svg>
            )
        case 'message':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-message"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="M4 21V8a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H8l-4 4M8 9h8M8 13h6" />
                </svg>
            )
        case 'show':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-eye"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <circle cx="12" cy="12" r="2" />
                    <path d="M22 12c-2.667 4.667-6 7-10 7s-7.333-2.333-10-7c2.667-4.667 6-7 10-7s7.333 2.333 10 7" />
                </svg>
            )
        case 'clock':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-clock"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <circle cx="12" cy="12" r="9" />
                    <path d="M12 7v5l3 3" />
                </svg>
            )
        case 'messages-open':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-messages"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="m21 14-3-3h-7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v10M14 15v2a1 1 0 0 1-1 1H6l-3 3V11a1 1 0 0 1 1-1h2" />
                </svg>
            )
        case 'messages-close':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-messages-off"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke={color}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M0 0h24v24H0z" stroke="none" />
                    <path d="m3 3 18 18M11 11a1 1 0 0 1-1-1m0-3.968V4a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v10l-3-3h-3M14 15v2a1 1 0 0 1-1 1H6l-3 3V11a1 1 0 0 1 1-1h2" />
                </svg>
            )
        case 'printer':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={size}
                    height={size}
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    className="icon icon-tabler icon-tabler-printer"
                    viewBox="0 0 24 24"
                >
                    <title id="printer-icon">{title}</title>
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M17 17h2a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h2M17 9V5a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v4" />
                    <rect width="10" height="8" x="7" y="13" rx="2" />
                </svg>
            )
        case 'mail':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={size}
                    height={size}
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    className="icon icon-tabler icon-tabler-mail"
                    viewBox="0 0 24 24"
                >
                    <title id="mail-icon">{title}</title>
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <rect width="18" height="14" x="3" y="5" rx="2" />
                    <path d="m3 7 9 6 9-6" />
                </svg>
            )
        case 'arrow-left':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={size}
                    height={size}
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    className="icon icon-tabler icon-tabler-arrow-left"
                    viewBox="0 0 24 24"
                >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M5 12h14M5 12l6 6M5 12l6-6" />
                </svg>
            )
        case 'refresh':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={size}
                    height={size}
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    className="icon icon-tabler icon-tabler-refresh"
                    viewBox="0 0 24 24"
                >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M20 11A8.1 8.1 0 0 0 4.5 9M4 5v4h4M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
                </svg>
            )
        case 'eye':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={size}
                    height={size}
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    className="icon icon-tabler icon-tabler-eye"
                    viewBox="0 0 24 24"
                >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx="12" cy="12" r="2" />
                    <path d="M22 12c-2.667 4.667-6 7-10 7s-7.333-2.333-10-7c2.667-4.667 6-7 10-7s7.333 2.333 10 7" />
                </svg>
            )
        case 'note':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={size}
                    height={size}
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    className="icon icon-tabler icon-tabler-note"
                    viewBox="0 0 24 24"
                >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="m13 20 7-7M13 20v-6a1 1 0 0 1 1-1h6V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7" />
                </svg>
            )
        case 'upload':
            return (
                <svg
                    width="80"
                    height="80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="80" height="80" rx="4" fill="#F7F7F9" />
                    <rect
                        x=".5"
                        y=".5"
                        width="79"
                        height="79"
                        rx="3.5"
                        stroke="#192132"
                        strokeOpacity=".15"
                        strokeDasharray="3 3"
                    />
                    <path
                        opacity=".5"
                        stroke="#192132"
                        d="M40.413 29v22M51 39.654H29"
                    />
                </svg>
            )
        case 'uploadimage':
            return (
                <svg
                    width="80"
                    height="80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="80" height="80" rx="4" fill="#F7F7F9" />
                    <rect
                        x=".5"
                        y=".5"
                        width="79"
                        height="79"
                        rx="3.5"
                        stroke="#192132"
                        strokeOpacity=".15"
                        strokeDasharray="3 3"
                    />
                    <path
                        opacity=".5"
                        d="m20 48.063 10.847-10.577a4.74 4.74 0 0 1 1.535-1 4.836 4.836 0 0 1 3.62 0 4.74 4.74 0 0 1 1.535 1l10.848 10.577m-3.154-3.076 2.962-2.888a4.74 4.74 0 0 1 1.535-1 4.836 4.836 0 0 1 3.62 0 4.741 4.741 0 0 1 1.536 1L61 48.063M23.154 55.75h34.692c.837 0 1.639-.324 2.23-.9A3.037 3.037 0 0 0 61 52.674v-24.6c0-.816-.332-1.598-.924-2.174a3.196 3.196 0 0 0-2.23-.901H23.154c-.837 0-1.639.324-2.23.9A3.037 3.037 0 0 0 20 28.076v24.6c0 .816.332 1.598.924 2.174.591.577 1.393.901 2.23.901ZM45.23 32.687h.017v.017h-.017v-.017Zm.788 0c0 .204-.083.4-.23.544a.799.799 0 0 1-1.116 0 .76.76 0 0 1 0-1.087.799.799 0 0 1 1.115 0c.148.144.231.34.231.544v0Z"
                        stroke="#192132"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )
        case 'remove-file':
            return (
                <svg
                    width="20"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="10" cy="10" r="10" fill="#FDE6E6" />
                    <path
                        d="m8 12 4-4M8 8l4 4"
                        stroke="#E46060"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )
        case 'polygon':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={size}
                    height={size}
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    className="icon icon-tabler icon-tabler-shape"
                    viewBox="0 0 24 24"
                >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx="5" cy="5" r="2" />
                    <circle cx="19" cy="5" r="2" />
                    <circle cx="5" cy="19" r="2" />
                    <circle cx="19" cy="19" r="2" />
                    <path d="M5 7v10M7 5h10M7 19h10M19 7v10" />
                </svg>
            )
        default:
            return null
    }
}

const Icon = ({ name, color, size, fill, title }) => {
    return renderIcon(name, color, size, fill, title)
}

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.number,
    title: PropTypes.string,
}

Icon.defaultProps = {
    color: '#CCD0D6',
    size: ICON_SIZE.LARGE,
    fill: 'none',
    title: '',
}

export default Icon
