import { Cartesian3, Color } from 'cesium'

const formatDMSValue = (value, length = 2) => `00${value}`.slice(-length)

export const isEmpty = (value) => {
    if (value === null || value === undefined || value === '') return true
    return false
}

export const formatPolylineCoordinates = (coordinates) => {
    const retVal = []
    coordinates.map((coordinate) => {
        if (coordinate === null) return null
        return retVal.push(coordinate[0], coordinate[1])
    })
    return Cartesian3.fromDegreesArray(retVal)
}

export const formatPolygonCoordinates = (coordinates) => {
    const retVal = []
    coordinates.forEach((coordinate) => {
        if (!coordinate) {
            return
        } else if (coordinate.lat && coordinate.lng) {
            retVal.push(coordinate.lng, coordinate.lat)
        } else if (
            coordinate[0] instanceof Array &&
            coordinate[1] instanceof Array
        ) {
            retVal.push(coordinate[0][0], coordinate[1][1])
        } else {
            retVal.push(coordinate[0], coordinate[1])
        }
    })
    return Cartesian3.fromDegreesArray(retVal)
}

export const generateRandomColor = () => {
    return Color.fromRandom({
        options: { red: 1, blue: 1, green: 1 },
    }).withAlpha(0.5)
}

export const getPolygonCenter = (coordinates) => {
    let minX, maxX, minY, maxY
    for (var i = 0; i < coordinates.length; i++) {
        minX = coordinates[i][0] < minX || !minX ? coordinates[i][0] : minX
        maxX = coordinates[i][0] > maxX || !maxX ? coordinates[i][0] : maxX
        minY = coordinates[i][1] < minY || !minY ? coordinates[i][1] : minY
        maxY = coordinates[i][1] > maxY || !maxY ? coordinates[i][1] : maxY
    }

    const deltaX = (minX + maxX) / 2
    const deltaY = (minY + maxY) / 2

    return Cartesian3.fromDegrees(deltaX, deltaY, 100)
}

export const formatNauticMilesToMeters = (value) => value * 1852

// This helper function is used for bug when significant points returns duplicate values
export const filterMapButtons = (mapButtons) => {
    return mapButtons.filter((item, index, retVal) => {
        return retVal.indexOf(item) === index
    })
}

export const convertRadiansToDegrees = (value) => {
    return (value * 180) / Math.PI
}

// NOTE:: isMin refers to lower latitude and longitude
export const calculateViewPortEdges = (value, type, isMin) => {
    // max latitude value is 90, max longitude value is 180
    const offsetIndex = 0.3
    if (type === 'latitude') {
        if (isMin && value >= -90 - offsetIndex) {
            return value - offsetIndex
        } else if (value <= 90 - offsetIndex) {
            return value + offsetIndex
        }
        return value
    } else {
        if (isMin && value >= -180 - offsetIndex) {
            return value - offsetIndex
        } else if (value <= 180 - offsetIndex) {
            return value + offsetIndex
        }
        return value
    }
}

const toDegreesMinutesAndSeconds = (coordinate, isLongitude = false) => {
    let absolute = Math.abs(coordinate)

    let degrees = Math.floor(absolute)
    //Degrees value on longitude is 3 digit long, and 2 for latitude
    degrees = formatDMSValue(degrees, isLongitude ? 3 : 2)

    let minutesNotTruncated = (absolute - degrees) * 60
    let minutes = Math.floor(minutesNotTruncated)
    minutes = formatDMSValue(minutes)

    return `${degrees}${minutes}`
}

export const formatLatLngToDMS = (lat, lng) => {
    const latitude = toDegreesMinutesAndSeconds(lat)
    const latitudeCardinal = lat >= 0 ? 'N' : 'S'

    const longitude = toDegreesMinutesAndSeconds(lng, true)
    const longitudeCardinal = lng >= 0 ? 'E' : 'W'

    return `${latitude}${latitudeCardinal}${longitude}${longitudeCardinal}`
}

export const formatDMStoLatLng = (coordinates) => {
    if (!coordinates) return null

    const lat =
        Number(coordinates.substr(0, 2)) + Number(coordinates.substr(2, 2)) / 60
    const lng =
        Number(coordinates.substr(6, 2)) + Number(coordinates.substr(8, 2)) / 60

    if (isNaN(lat) || isNaN(lng)) return null

    const latitudeCardinal = coordinates.substr(4, 1)
    const longitudeCardinal = coordinates.substr(10, 1)

    let latValue = lat
    let lngValue = lng

    if (latitudeCardinal === 'S') {
        latValue = 0 - lat
    }
    if (longitudeCardinal === 'W') {
        lngValue = 0 - lng
    }

    return { lat: latValue, lng: lngValue }
}

export const formatDMStoLatLngArray = (coordinates) => {
    if (!coordinates) return null
    const { lat, lng } = formatDMStoLatLng(coordinates)
    return [lng, lat]
}

export const formatDMSObjectToString = (latitude, longitude) => {
    if (!latitude || !longitude) return null

    const latDegrees = formatDMSValue(latitude.Degree)
    const latMinutes = formatDMSValue(latitude.Minute)

    const lngDegrees = formatDMSValue(longitude.Degree, 3)
    const lngMinutes = formatDMSValue(longitude.Minute)

    const lat = `${latDegrees}${latMinutes}${latitude.Direction}`
    const lng = `${lngDegrees}${lngMinutes}${longitude.Direction}`

    return `${lat}${lng}`
}

export const cutSeconds = (coordinates, isLng) => {
    if (coordinates.includes('.')) {
        const splitCoordinates = coordinates.split('.')
        if (splitCoordinates[0].length >= 4) {
            return splitCoordinates[0].substr(
                0,
                isLng && coordinates.length >= 7 ? 5 : 4
            )
        }
        return
    }

    if (coordinates.length >= 5) {
        return coordinates.substr(0, isLng && coordinates.length === 8 ? 5 : 4)
    }
}

const formatDMSforNotams = (coordinates) => {
    const lat =
        Number(coordinates.substr(0, 2)) + Number(coordinates.substr(2, 2)) / 60
    const lng =
        Number(coordinates.substr(4, coordinates.length === 9 ? 3 : 2)) +
        Number(coordinates.substr(coordinates.length === 9 ? 7 : 6, 2)) / 60
    return [lng, lat]
}

export const formatNotamsDMSToLatLng = (lat, lng) => {
    const formatedCoordinatesLat = cutSeconds(lat)
    const formatedCoordinatesLng = cutSeconds(lng, true)
    if (!formatedCoordinatesLat || !formatedCoordinatesLng) return
    return formatDMSforNotams(
        `${formatedCoordinatesLat}${formatedCoordinatesLng}`
    )
}

export const formatDMSObjectToLatLng = ({ Latitude, Longitude }) => {
    if (!Latitude || !Longitude) return
    const lat = Latitude.Coordinate.Degree + Latitude.Coordinate.Minute / 60
    const lng = Longitude.Coordinate.Degree + Longitude.Coordinate.Minute / 60
    return {
        lat: Latitude.Coordinate.Direction === 'N' ? lat : -lat,
        lng: Longitude.Coordinate.Direction === 'E' ? lng : -lng,
    }
}

// e.g. Aerodrome name: SANTAREM HOSPITAL 3915N00842W
// SANTAREM is not a coordinate
// HOSPITAL is not a coordinate
// 3915N00842W is a coordinate
export const isCoordinate = (value) =>
    value.length === 11 &&
    ['N', 'S'].includes(value[4]) &&
    ['E', 'W'].includes(value[10])
